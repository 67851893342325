<template>
  <section style="background: #f7f7f7">
    <div class="position-relative">
      <div class="row">
        <div class="col-lg-12 col-12">
          <v-img src="/homes/zar2.jpg" height="600"></v-img>
        </div>
      </div>
      <div class="feature3-card col-md-7 col-lg-4 col-12">
        <v-card width="500">
          <v-card-text>
            <div class="pa-10 text-center">
              <!-- <span
                    class="v-chip theme--light v-size--small info white--text"
                    ><span class="v-chip__content"> </span></span
                  > -->
              <v-chip
                class="info"
                style="background: #4caf54 !important; color: white !important"
                >New Art of Travelling
              </v-chip>
              <h3 class="feature3-title font-weight-medium">
                The New way of Getting Trusted Information on Mongolia
              </h3>
              <p class="description">
                You can relay on our amazing features list and also our customer
                services will be great experience. You will love it for sure.
              </p>
              <a
                href="/"
                aria-current="page"
                class="
                  mt-10
                  btn-arrow
                  v-btn--active
                  v-btn v-btn--has-bg v-btn--router
                  elevation-0
                  v-size--large
                  bg-info-grediant
                "
                ><span style="color: white !important">Explore</span>
                <i
                  class="mdi mdi-arrow-right"
                  style="color: white !important"
                ></i>

                ></a
              >
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style>
.feature3-card {
  position: absolute;
  top: 17%;
  right: 8%!important;
}
.feature3-card .feature3-title {
  font-size: 24px;
  line-height: 30px;
  margin: 20px 0 15px;
  color: #3e4555 !important;
}
.bg-info-grediant {
  /* background: #18cc10;
  background: linear-gradient(90deg, #18cc10, #81c784); */
  background: #f53939;
  background-image: linear-gradient(310deg, #f53939, #fbcf33) !important;
}
</style>